.footer
  &-choice
    background: url("../images/light-moon.png") no-repeat top center
    text-align: center
    padding-top: 100px
    padding-bottom: 200px

    &-title
      font-size: 50px
      margin-bottom: 50px

      @media only screen and (max-width: 1199px)
        font-size: 45px

      @media only screen and (max-width: 767px)
        font-size: 40px


    &-text
      font-size: 24px
      line-height: 1.4
      margin-bottom: 30px

    @media only screen and (max-width: 767px)
      font-size: 18px



  &-contacts
    background: url("../images/footer-bg-shape1.png") no-repeat top center
    padding-top: 200px
    padding-bottom: 100px
    margin-top: -150px

    &-row
      display: flex
      justify-content: space-between
      align-items: center

      @media only screen and (max-width: 767px)
        flex-wrap: wrap
        justify-content: center

    &-list
      display: flex

      @media only screen and (max-width: 767px)
        margin-bottom: 30px

    &-item
      display: block

      &:nth-child(-n+2)
        margin-right: 20px

      &>a
        display: flex
        width: 32px
        height: 32px

        &>svg
          display: block
          width: 100%
          height: auto

    &-copy
      @media only screen and (max-width: 767px)
        width: 100%
        text-align: center
