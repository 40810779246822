@keyframes move-twink-back
  from
    background-position-x: 0
    background-position-y: 0

  to
    background-position-x: -10000px
    background-position-y: 6000px


@keyframes move-right-left
  0%
    background-position-x: 0
  50%
    background-position-x: 100%
  100%
    background-position-x: 0

@keyframes rotation
  from
    transform: rotate(0)

  to
    transform: rotate(360deg)

@keyframes shadow
  0%
    box-shadow: 0 0 2px 0 rgba($yellow, .35)

  50%
    box-shadow: 0 0 2px 20px rgba($yellow, .35)

  60%
    box-shadow: 0 0 2px 20px rgba($yellow, 0)

  100%
    box-shadow: 0 0 2px 20px rgba($yellow, 0)


@keyframes swaying
  0%
    transform: rotate(0)
  5%
    transform: rotate(-30deg)
  10%
    transform: rotate(30deg)
  15%
    transform: rotate(0)
  100%
    transform: rotate(0)
