//'Comfortaa', cursive
//Arial, sans-serif
//Georgia, serif

//$src: 'http://localhost:9000'
$src: 'https://veretnica.ru'

@font-face
  $font: '/fonts/hinted-Bukyvede.woff'
  font-family: 'bukyvede'
  src: url($src + $font + 2) format('woff2'), url($src + $font) format('woff')
  font-weight: 400
  font-style: normal
  font-display: swap

$font-title: 'bukyvede', Arial, sans-serif
$font-body: 'Yanone Kaffeesatz', Arial, sans-serif
