*
  padding: 0
  margin: 0

//html
//  overflow-x: hidden

body
  display: flex
  flex-direction: column
  align-items: stretch
  width: 100%
  min-height: 100vh
  font-weight: 300

  min-width: 320px
  background-color: $dark
  font-family: $font-body
  color: $light
  font-size: 16px

main
  display: block

h1, h2, h3, h4, h5, h6
  overflow-wrap: break-word
  font-family: $font-title
  color: $yellow
  //font-weight: 300

p
  overflow-wrap: break-word
  margin: 0

address
  font-style: normal

input,
textarea
  border: solid 0 transparent
  outline: 0

input, select, textarea
  font-size: 16px

input[type="number"]
  appearance: none

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button
    display: none

button
  border: 0
  background-color: transparent
  cursor: pointer

.wrapper
  flex: 1 0 auto

.container
  box-sizing: border-box
  //max-width: 1240px
  max-width: 1410px
  width: 100%
  padding-left: 30px
  padding-right: 30px
  margin-left: auto
  margin-right: auto

  //@media only screen and (max-width: 1680px)
  //  padding-left: 85px
  //  padding-right: 85px

  @media only screen and (max-width: 1199px)
    //width: 100%
    padding-left: 25px
    padding-right: 25px

  @media only screen and (max-width: 767px)
    padding-left: 16px
    padding-right: 16px

.sr-only
  position: absolute
  width: 0
  max-width: 0
  height: 0
  padding: 0
  overflow: hidden
  clip: rect(0, 0, 0, 0)
  white-space: nowrap
  clip-path: inset(50%)
  border: 0
