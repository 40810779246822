.i
  &-main
    box-sizing: border-box
    position: relative
    padding-top: 200px
    padding-bottom: 200px

    @media only screen and (max-width: 1680px)
      padding-top: 200px
      padding-bottom: 200px

    @media only screen and (max-width: 1199px)
      padding-top: 200px
      padding-bottom: 30px

    @media only screen and (max-width: 767px)
      padding-top: 100px
      padding-bottom: 0


    &::before
      position: absolute
      content: ''
      display: block
      bottom: 0
      left: 0
      width: 100%
      height: 200px
      background: url("../images/bottom-main.png") no-repeat top left
      background-size: cover
      z-index: 1

      @media only screen and (max-width: 767px)
        height: 100px

    &-content
      position: relative
      text-align: center
      z-index: 1

    &-title
      color: $yellow
      font-size: 80px
      font-weight: 700
      letter-spacing: 0.3rem
      margin-bottom: 150px

      @media only screen and (max-width: 1199px)
        font-size: 60px

      @media only screen and (max-width: 767px)
        font-size: 40px
        line-height: 1.4
        margin-bottom: 100px


      & > span
        display: block
        font-size: 50px

        @media only screen and (max-width: 1199px)
          font-size: 40px

        @media only screen and (max-width: 767px)
          font-size: 30px



    &-text
      color: $light
      font-size: 80px
      margin-bottom: 150px

      @media only screen and (max-width: 1199px)
        font-size: 60px

      @media only screen and (max-width: 767px)
        font-size: 40px
        margin-bottom: 100px

    &-tel
      box-sizing: border-box
      display: inline-block
      color: $light
      text-decoration: none
      font-size: 18px
      font-weight: 500
      border: 2px solid $yellow
      border-radius: 20px
      padding: 10px 30px
      transition: color .3s ease, background-color .3s ease

      &:hover
        color: $dark
        background-color: $light

      @media only screen and (max-width: 767px)
        font-size: 16px

  &-stars
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: $grey-dark url("../images/stars.png") repeat top center

  &-twinkling
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: transparent url("../images/twinkling.png") repeat top center
    animation: move-twink-back 200s linear infinite

  &-mystic
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: url("../images/mystic-main.png")
    background-size: contain
    background-repeat: no-repeat
    background-position: right 0

    @media only screen and (max-width: 767px)
      background-position: center

  &-about
    padding-top: 50px
    padding-bottom: 50px

    @media only screen and (max-width: 1199px)
      padding-top: 150px
      padding-bottom: 50px

    &-grid
      display: grid
      grid-template-columns: repeat(2, 1fr)
      gap: 80px

      @media only screen and (max-width: 767px)
        grid-template-columns: repeat(1, 1fr)
        gap: 30px


    &-images
      position: relative
      padding-top: 100%

      &-stars,
      &-planets,
      &-static
        position: absolute
        box-sizing: border-box
        top: 0
        left: 0
        width: 100%
        height: 100%
        overflow: hidden

        &>img
          display: block
          width: 100%
          height: auto

      &-stars
        padding: 3%

        & > img
          animation: rotation 100s linear infinite

      &-planets
        padding: 12.5%
        z-index: 1

        & > img
          animation: rotation 80s linear infinite reverse

      &-static
        padding: 9%

    &-content
      display: flex
      flex-direction: column
      //align-items: stretch
      justify-content: center

      @media only screen and (max-width: 767px)
        text-align: center

    &-title
      font-size: 80px
      margin-bottom: 20px

      @media only screen and (max-width: 1680px)
        font-size: 70px

      @media only screen and (max-width: 1199px)
        font-size: 60px

      @media only screen and (max-width: 767px)
        font-size: 40px


    &-text
      color: $light
      font-size: 24px
      line-height: 1.5
      margin-bottom: 14px

      @media only screen and (max-width: 1680px)
        font-size: 22px

      @media only screen and (max-width: 1199px)
        font-size: 19px

      @media only screen and (max-width: 767px)
        font-size: 16px


  &-services
    position: relative
    overflow: hidden
    box-sizing: border-box
    padding-top: 100px
    padding-bottom: 200px

    @media only screen and (max-width: 1199px)
      padding-top: 80px
      padding-bottom: 100px

    @media only screen and (max-width: 767px)


    &-chakra
      position: absolute
      display: block
      top: 0
      left: calc(50% - 384px)
      width: 100%
      max-width: 768px
      animation: rotation 100s linear infinite

      @media only screen and (max-width: 767px)
        left: 0

    &-title
      text-align: center
      font-size: 80px
      font-weight: 400
      margin-bottom: 50px

      @media only screen and (max-width: 1199px)
        font-size: 50px

      @media only screen and (max-width: 767px)
        font-size: 40px



    //&-grid
    //  display: grid
    //  grid-template-columns: repeat(3, 1fr)
    //  gap: 40px
    //  margin-bottom: 70px
    //
    //  @media only screen and (max-width: 1199px)
    //    grid-template-columns: repeat(2, 1fr)
    //    gap: 40px 60px
    //
    //  @media only screen and (max-width: 579px)
    //    display: block

    &-slider
      margin-bottom: 50px

      & .swiper-pagination
        display: flex
        justify-content: center
        margin-top: 30px

        @media only screen and (max-width: 767px)
          margin-top: 10px

        &-bullet
          display: block
          border-radius: 50%
          background-color: $light
          padding: 5px
          margin-right: 10px
          margin-left: 10px

          &-active
            background-color: $yellow

    &-slide
      height: auto!important
      cursor: grab

    &-card
      box-sizing: border-box
      background: $light url("../images/clouds.png") no-repeat
      background-size: cover
      animation: move-right-left 30s linear infinite
      border-radius: 5px
      padding: 35px 50px
      height: 100%

      @media only screen and (max-width: 579px)
        padding: 10px 20px

        &:nth-child(n+2)
          margin-top: 20px

      & > h3
        color: $dark
        font-size: 28px
        margin-bottom: 20px

        @media only screen and (max-width: 1680px)
          font-size: 26px

        @media only screen and (max-width: 1199px)
          font-size: 23px

        @media only screen and (max-width: 767px)
          font-size: 20px
          margin-bottom: 10px

      & > ul
        color: $dark
        font-size: 20px
        font-weight: 300
        list-style-position: inside
        line-height: 1.4

        @media only screen and (max-width: 1199px)
          font-size: 18px

        @media only screen and (max-width: 767px)
          font-size: 16px


    &-footer
      position: relative
      text-align: center

      &>p
        font-size: 24px
        margin-bottom: 30px

        @media only screen and (max-width: 767px)
          font-size: 20px



  &-garantii
    background-color: $dark
    background: url("../images/dark-moon-1.png") no-repeat
    background-position: right 7% top 30%
    padding-top: 50px
    padding-bottom: 100px

    @media only screen and (max-width: 1199px)
      background-position: right -50% top 10%

    &-title
      text-align: center
      font-size: 50px
      margin-bottom: 50px

      @media only screen and (max-width: 1680px)
        font-size: 40px

      @media only screen and (max-width: 1199px)
        font-size: 35px

      @media only screen and (max-width: 767px)
        font-size: 30px

      &>span
        display: block

    &-grid
      display: grid
      grid-template-columns: repeat(4, 1fr)
      gap: 50px

      @media only screen and (max-width: 1199px)
        grid-template-columns: repeat(2, 1fr)

      @media only screen and (max-width: 767px)
        grid-template-columns: repeat(1, 1fr)
        gap: 30px

    &-badge
      position: relative
      margin-bottom: 30px

    &-shine
      &>img
        display: block
        width: 100%
        max-width: 160px
        height: auto
        animation: rotation 80s linear infinite
        margin-right: auto
        margin-left: auto

    &-card:nth-child(2n+2)
      & .i-garantii-shine
        &>img
          animation: rotation 80s linear infinite reverse

    &-icon
      position: absolute
      box-sizing: border-box
      display: flex
      align-items: center
      justify-content: center
      background-color: $light
      top: 50%
      left: 50%
      width: 100px
      height: 100px
      padding: 20px
      border-radius: 50%
      transform: translate(-50%, -50%)

      & > img
        display: block
        width: 100%
        height: auto

    &-content
      text-align: center

    &-subtitle
      font-size: 24px
      margin-bottom: 20px

    &-text
      font-size: 18px
      line-height: 1.4

      @media only screen and (max-width: 767px)
        font-size: 16px

  &-touch
    position: fixed
    display: none
    box-sizing: border-box
    right: 40px
    bottom: 40px
    width: 60px
    height: 60px
    background-color: $dark
    border-radius: 50%
    box-shadow: 0 0 2px 20px rgba($yellow, .35)
    animation: shadow ease-out 2s infinite
    z-index: 1000

    @media only screen and (max-width: 1199px)
      display: block

    & > a
      display: block
      padding: 15px

      & > svg
        fill: $light
        animation: swaying 5s linear infinite

